<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="assignTwo-cnatiner">
        <div class="overflow-container">
            <div class="top-flex">
                <assign-step :stepActive="2"></assign-step>
                <div class="grey-line"></div>
            </div>
            <div class="content-flex">
                <el-form ref="formSupplier" :model="supplierForm" :rules="supplierRules" label-width="140px" class="demo-ruleForm">
                    <div class="supplier-form">
                        <el-form-item label="公司名称：" prop="companyId">
                            <el-select placeholder="请选择公司" class="common-screen-input_100" @change="(val) => { companyFun(val) }" v-model="supplierForm.companyId" filterable>
                                <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                            </el-select>
                        </el-form-item> 
                        <el-form-item label="订单名称：" prop="name">
                            <el-input class="common-screen-input_100" placeholder="订单名称" v-model="supplierForm.name"></el-input>
                        </el-form-item> 
                        <el-form-item label="业务类型：" prop="type">
                            <el-select placeholder="请选择业务类型" class="common-screen-input_100" v-model="supplierForm.type" filterable>
                                <el-option label="DSR修改" value="103" ></el-option>
                                <el-option label="客服外包" value="104" ></el-option>
                                <el-option label="电话销售客服" value="111" ></el-option>
                                <el-option label="中差评修改" value="144" ></el-option>
                            </el-select>
                        </el-form-item> 
                        <!-- <el-form-item label="金额：" prop="price">
                            <el-input class="common-screen-input_100" placeholder="金额" v-model="supplierForm.price"></el-input>
                        </el-form-item>  -->
                        <el-form-item label="订单收款方式" prop="paymentMethods">
                            <el-select placeholder="请选择通知人" class="common-screen-input_100" v-model="supplierForm.paymentMethods" filterable>
                                <el-option v-for="item in payType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="通知人：" prop="noticeId">
                            <el-select placeholder="请选择通知人" class="common-screen-input_100" v-model="supplierForm.noticeId" filterable>
                                <el-option v-for="item in comPeopleList" :key="item.id" :label="item.nickName" :value="item.id" ></el-option>
                            </el-select>
                        </el-form-item> 
                        <el-form-item label="备注：" >
                            <el-input type="textarea" class="common-screen-input_100"  :autosize="{ minRows: 3, maxRows: 3 }" v-model="supplierForm.remark" maxLength="500" placeholder="请填写备注"></el-input>
                        </el-form-item> 
                        <el-form-item label="附件：" >
                            <div class="orderFile-container">
                                <div class="orderFileBtn"> 上传附件 <input class="orderFile-input" type="file" @input="(e) => {uploadFile(e)}"></div>
                                上传文件及图片，大小不超过50MB
                            </div>
                            <div class="fileList-container">
                                <div v-for="(item, index) in fileList" :key="index" class="fileList-item">
                                    <div class="item-name">
                                        <common-picture :fileUrl="item.filedUrl" :fileType="Config.fileType.imgType.includes(item.filedName.split('.')[item.filedName.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.filedName"></common-picture>
                                    </div>
                                    <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                                </div>
                            </div>
                        </el-form-item> 
                    </div>
                </el-form>
            </div>
        </div>
        <div class="footer-container">
            <div class="grey-line"></div>
            <div class="footer-content">
                <div>
                    <el-button class="back-btn" plain @click="() => { $router.push(`/assign-order/assign-list/assign-one?id=${this.$route.query.id}${this.$route.query.isEdit ? ('&isEdit=' + this.$route.query.isEdit) : ''}`) }">上一步：店铺信息填报</el-button>
                    <el-button class="next-btn" type="primary" @click="() => { saveAssignOrder('formSupplier') }">下一步：生成订单</el-button>
                </div>
                <div>
                    <!-- <el-button class="next-btn" plain>重置此页</el-button> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssignStep from "./children/AssignStep.vue"
import { Config } from "../../utils/index.js"
import { upload } from "../../service/upload.js";
import CommonPicture from "../../components/common/commonPicture.vue"
import { saveAssignOrder, assignOrderUpload, assignOrderDelete, assignOrderDetail } from "../../service/assignOrder.js"
import { selectNotice } from "../../service/common.js"
export default {
    components: { AssignStep, CommonPicture },
    data () {
        return {
            Config,
            loading: false,
            supplierForm: {
                paymentMethods: ""
            },
            supplierRules: {
                companyId: [{ required: true, message: "请选择公司", trigger: "change" }],
                name: [{ required: true, message: "请填写订单名称", trigger: "change" }],
                type: [{ required: true, message: "请选择业务类型", trigger: "change" }],
                // price: [{ required: true, message: "请填写金额", trigger: "change" }],
                noticeId: [{ required: true, message: "请选择通知人", trigger: "change" }],
                paymentMethods: [{ required: true, message: "请选择订单收款方式", trigger: "change" }],
            },
            rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
            comPeopleList: [], // 通知人
            fileList: [], // 文件列表
            payType: [
              {id: 0,name: '前置收款'},
              {id: 1,name: '前置服务'},
              {id: 2,name: '免费试用'},
            ],
            paymentMethods: null
        }
    },
    created () {
        if (this.$route.query.id) {
            this.assignOrderDetail()
        }
         // ??????? 接口待替换
    },
    methods: {
        async assignOrderDetail() { // 返回编辑获取详情
            let { data } = await assignOrderDetail({disOrderNo: this.$route.query.id})
            if (data) {
                if(data.paymentMethods){ //前置收款，前置**
                    this.payType.forEach((item)=>{
                        if(data.paymentMethods == item.name){
                            this.paymentMethods = item.id
                        }
                    })
                }
                this.supplierForm = {
                    companyId: data.companyId || '',
                    name: data.disOrderName || '',
                    type: data.type ? data.type + '' : '',
                    noticeId: data.noticeId || '',
                    remark: data.remark || '',
                    paymentMethods: (this.paymentMethods||this.paymentMethods =='0'? this.paymentMethods: ''),
                }
                if (data.fileList && data.fileList.length) {
                    this.fileList = data.fileList
                }
                if (data.companyId) {
                    this.selectNotice(data.companyId);
                }
            }
            if (this.$refs['formSupplier']) {
                this.$refs['formSupplier'].resetFields()
            }
        },
        companyFun(val) { // 选择公司，改变通知人下拉
            console.log(val)
            this.supplierForm.noticeId = ''
            this.selectNotice(val);
        },
        async selectNotice(companyId) { // 获取客服下拉
            let { data } = await selectNotice({companyId});
            this.comPeopleList = data;
        },
        saveAssignOrder (formName) { // 保存第二部提交
            this.$refs[formName].validate(async (valid) => {
                if (valid) { // 发送请求
                    let supplierForm = JSON.parse(JSON.stringify(this.supplierForm))
                    supplierForm.stepNum = 2
                    supplierForm.disOrderNo = this.$route.query.id
                    let { data } = await saveAssignOrder(supplierForm)
                    this.$router.push(`/assign-order/assign-list/assign-three?id=${data}${this.$route.query.isEdit ? ('&isEdit=' + this.$route.query.isEdit) : ''}`)
                } else {
                this.$message.error("请填写完整的信息");
                }
            });
        },
        async uploadFile (e) { // 上传文件
            let fileList = [...this.fileList]
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let resData = (await upload({ file: file })).data;
            let { code } = await assignOrderUpload({disOrderNo: this.$route.query.id, filedId: resData.id, filedUrl: resData.filename, fileName: fileName})
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("上传成功")
                fileList.push({disOrderNo: this.$route.query.id, filedName: fileName, filedUrl: resData.filename, filedId: resData.id})
                this.fileList = fileList
            }
            e.target.value = ''
        },
        async deleteFlie (i) {  //  删除文件
            let fileList = [...this.fileList]
            let deleteItem = {}
            fileList.map((item, index) => {
                if (i == index) {
                    deleteItem = item
                    deleteItem.disOrderNo = this.$route.query.id
                }
            })
            deleteItem.disOrderNo = this.$route.query.id
            this.loading = true
            let { code } = await assignOrderDelete(deleteItem)
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("删除成功")
                fileList = fileList.filter((item,index)=>{
                    return i != index
                })
                this.fileList = fileList
            }
        },
    }
}
</script>
<style lang="less" scoped>
.assignTwo-cnatiner {
    text-align: left;
    position: relative;
    height: 100%;
    background: #fff;
    .overflow-container {
        height: 100%;
        box-sizing: border-box;
        padding: 0 0 84px;
        display: flex;
        flex-direction: column;
        .top-flex {
            height: 164px;
            width: 100%;
        }
        .content-flex {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    .fileList-container {
        .fileList-item {
            display: flex;
            justify-content: space-between;
            width: 320px;
            .item-name {
            font-size: 12px;
            color: #333;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            }
            .deleteBtn {
            color: #1890ff;
            cursor: pointer;
            }
        }
    }
    .orderFile-container {
        .orderFileBtn {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border:  1px solid rgba(220, 223, 230, 100);
            color: #666;
            background: #fff;
            text-align: center;
            font-size: 14px;
            position: relative;
            border-radius: 4px;
            display: inline-block;
            .orderFile-input {
                width: 100px;
                height: 32px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                font-size: 0;
            }
        }
        
    }
    .supplier-form {
        width: 30%;
        padding-left: 102px;
        padding-top: 62px;
    }
    .footer-container {
        height: 100px;
        width: 100%;
        // box-sizing: border-box;
        padding: 0 16px;
        background: #fff;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 9999;
        .footer-content {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            height: 84px;
            align-items: center;
            box-sizing: border-box;
            padding-right: 120px;
            padding-left: 100px;
            .back-btn {
                height: 38px;
                border: 1px solid #1890ff;
                color: #1890ff;
                margin-right: 16px;
            }
            .next-btn {
                height: 38px;
            }
        } 
    }
    .grey-line {
        height: 16px;
        width: 120%;
        margin: 0 0 0 -10%;
        background: #eee;
    }
}
</style>